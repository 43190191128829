<template>
  <AddEditCountry
    :display="showAddEditCountry"
    :countryIndex="editCountryIndex"
    @closeAddCountry="closeAddCountry"
  ></AddEditCountry>
  <BulkImport
    :display="showBulkImport"
    :type="'country'"
    @closeImport="closeBulkImport"
    @importData="importData"
  ></BulkImport>

  <Card>
    <template #title>
      <div class="grid">
        <div class="col-6 md:col-2">
          <h4>{{ $t("settings.countries") }}</h4>
        </div>
        <div class="text-center text-right col-6 md:col-2">
          <Button
            v-if="!isMobile"
            :label="$t('global.export')"
            @click="exportCSV"
          ></Button>
        </div>
        <div class="col-6 md:col-2 text-left md:text-right">
          <Button
            :label="$t('settings.bulkDownload')"
            @click="bulkExport"
          ></Button>
        </div>
        <div class="col-6 md:col-2 text-right">
          <Button
            :label="$t('settings.bulkAddition')"
            @click="bulkCountry"
          ></Button>
        </div>

        <div class="col-6 md:col-2 text-left md:text-right">
          <Button
            :label="$t('settings.addCountry')"
            @click="addCountry"
            class="mr-3"
          ></Button>
        </div>
        <div class="col-6 md:col-2 text-right">
          <Button :label="$t('global.saveJson')" @click="exportJson"></Button>
        </div>
      </div>
    </template>
    <template #content>
      <DataTable
        :value="countriesValues"
        @rowReorder="onRowReorder"
        responsiveLayout="scroll"
        showGridlines
        :loading="loading"
        ref="countriesTable"
        @page="changePage"
      >
        <Column
          :rowReorder="true"
          :reorderableColumn="false"
          headerStyle="width: 3rem"
          class="text-center"
        />
        <Column field="id" :header="$t('settings.code')">
          <template #body="{ data }">
            <div class="text-center">
              {{ data.id }}
            </div>
          </template>
        </Column>
        <Column field="alpha2Code" header="2 code">
          <template #body="{ data }">
            <div class="text-center">
              {{ data.alpha2Code }}
            </div>
          </template>
        </Column>
        <Column field="alpha3Code" header="3 code">
          <template #body="{ data }">
            <div class="text-center">
              {{ data.alpha3Code }}
            </div>
          </template>
        </Column>
        <Column field="nameGreek" :header="$t('settings.nameGreek')">
          <template #body="{ data }">
            <div class="text-center">
              {{ data.nameGreek }}
            </div>
          </template>
        </Column>
        <Column field="nameEnglish" :header="$t('settings.nameEnglish')">
          <template #body="{ data }">
            <div class="text-center">
              {{ data.nameEnglish }}
            </div>
          </template>
        </Column>
        <Column :header="$t('global.actions')">
          <template #body="{ data, index }">
            <div class="flex justify-content-evenly align-items-center">
              <Button
                class="p-button-text mdi mdi-24px mdi-pencil"
                @click="editCountry(index)"
              />
              <Button
                icon="mdi mdi-close-circle mdi-24px"
                class="p-button-rounded p-button-text p-button-text"
                @click="deleteCountry(data, index)"
              />
            </div>
          </template>
        </Column>
      </DataTable>
    </template>
    <!-- <Column header="test">
          <template #body>
            <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACwAAAAeAQMAAABt+G7kAAAAA1BMVEVMaXFNx9g6AAAAAXRSTlMAQObYZgAAAAtJREFUeF5jGHYAAADSAAEzF5gpAAAAAElFTkSuQmCC" class="flag flag-ro" width="30" data-v-4da3ac46="">
          </template>
        </Column> -->
  </Card>
</template>

<script>
import { mapGetters } from "vuex";
import AddEditCountry from "./AddEditCountry.vue";
import BulkImport from "../BulkImport.vue";
export default {
  name: "Countries",
  components: {
    AddEditCountry,
    BulkImport,
  },
  data() {
    return {
      countriesValues: [],
      showAddEditCountry: false,
      showBulkImport: false,
      loading: false,
      editCountryIndex: null,
      currentPage: 0,
      currentRows: null,
    };
  },
  async mounted() {
    this.getCountries();
  },
  computed: {
    ...mapGetters(["countries", "isMobile"]),
  },
  watch: {
    countries() {
      this.countriesValues = [...this.countries];
    },
  },
  methods: {
    changePage(data) {
      this.currentPage = data.page;
      this.currentRows = data.rows;
    },
    async getCountries() {
      this.loading = true;
      await this.$store.dispatch("countries");
      this.loading = false;
    },
    addCountry() {
      this.showAddEditCountry = true;
    },
    closeAddCountry(newCountry) {
      this.showAddEditCountry = false;
      this.editCountryIndex = null;

      if (newCountry) {
        this.countriesValues.push(newCountry);
        this.$store.commit("countries", this.countriesValues);
      } else {
        this.countriesValues = this.countries;
      }
    },
    bulkCountry() {
      this.showBulkImport = true;
    },
    closeBulkImport() {
      this.showBulkImport = false;
    },
    onRowReorder(event) {
      console.log(event);
      this.countriesValues = [...event.value];
      console.log(this.countriesValues);
    },
    async importData(data) {
      await this.$store.dispatch("updateCountries", data);
    },
    async bulkExport() {
      await this.$store.dispatch("bulkExport", "countries");
    },
    exportJson() {
      this.$store.dispatch("exportJson", {
        name: "countries",
        json: this.countriesValues,
      });
    },
    editCountry(index) {
      this.showAddEditCountry = true;
      this.editCountryIndex = index + this.currentPage * this.currentRows;
    },
    deleteCountry(country, countryIndex) {
      console.log(country);
      this.$confirm.require({
        message: this.$t("settings.deleteCountry", [country.nameGreek]),
        header: this.$t("settings.deleteConfirmation"),
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        acceptLabel: this.$t("global.yes"),
        rejectLabel: this.$t("global.no"),
        accept: () => {
          this.countriesValues.splice(
            countryIndex + this.currentPage * this.currentRows,
            1
          );
          this.$store.commit("countries", this.countriesValues);
        },
      });
    },
    exportCSV() {
      this.$refs.countriesTable.exportCSV();
    },
  },
};
</script>
